import React from "react"
import { Link } from "gatsby"
import DynamicImage from "../components/dynimage"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactUs = () => (
  <Layout>
    <SEO title="Contact Us" />
    {/* <!--================Common Banner Area =================--> */}
    <section className="banner_area banner_area_04">
      <div className="banner_inner d-flex align-items-center">
        <div
          className="overlay bg-parallax"
          data-stellar-ratio="0.9"
          data-stellar-vertical-offset="0"
          data-background=""
        ></div>
        <div className="container">
          <div className="banner_content text-center">
            <h2>Contact Us</h2>
            <div className="page_link">
              <Link to="/">Home</Link>
              <Link to="#contact">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--================End Common Banner Area =================--> */}
    {/* <!--================Contact Area =================--> */}
    <section className="contact_area p_120">
      <div className="container">
        <div className="main_title">
          <p>We value our Customers & love to hear from you all...</p>
          <p>
            Choose to contact us over Phone Email, or reach us using plain old
            snail-mail
          </p>
        </div>
        <div className="row mb-40">
          <div className="col-lg-6">
            <div className="contact_info">
              <h3>USA</h3>
              <div className="info_item">
                <i className="lnr lnr-home"></i>
                <h6>Florida, USA</h6>
                <p>15607 Howell Park Lane, Tampa. Florida. 33625. USA</p>
              </div>
              <div className="info_item">
                <i className="lnr lnr-phone-handset"></i>
                <h6>
                  <a title="+1 813 420 5200" href="tel:+1 813 420 5200">
                    +1 813 420 5200{" "}
                  </a>
                </h6>
                <p>Mon to Fri 9am to 6 pm</p>
              </div>
              <div className="info_item">
                <i className="lnr lnr-envelope"></i>
                <h6>
                  <a
                    title="support@nglinx.com"
                    href="mailto:support@nglinx.com"
                  >
                    support@nglinx.com
                  </a>
                </h6>
                <p>Send us your query anytime!</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-flex">
            <div className="contact_image">
              <DynamicImage src="contact-us.jpg" />
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--================Contact Area =================--> */}
  </Layout>
)

export default ContactUs
